// Global
import { ThemeUpperCase, Theme, SiteName } from 'components/helpers/Constants';
import { createContext, useContext } from 'react';

export const THEME_MAPPING = { psp: ['psp' as SiteName], wnw: ['wagnwash' as SiteName] };

export const ALL_THEMES = Object.keys(THEME_MAPPING) as Theme[];

export type ThemeFile = {
  [key in Theme]: unknown;
};

export const ThemeContext = createContext<Theme>(ALL_THEMES[0]);

export const useTheme = (themeFile?: ThemeFile) => {
  const themeName = useContext(ThemeContext);
  const themeNameUpper = themeName.toUpperCase() as ThemeUpperCase;
  const themeData = themeFile ? themeFile[themeName] : undefined;
  return { themeName, themeNameUpper, themeData };
};
