import type { AppProps } from 'next/app';
import { I18nProvider } from 'next-localization';
import { SitecorePageProps } from 'lib/page-props';
import Bootstrap from 'src/Bootstrap';
import { ThemeContext } from 'lib/context/ThemeContext';
import { getTheme } from 'lib/multisite/theme-mapping';
import '../assets/customCalender.css';
import '../assets/app.css';
import '../assets/typography.css';
import { defaultConfig } from 'tailwind-variants';
import { CookiesProvider } from 'react-cookie';
// prevents to override tailwind classes.
defaultConfig.twMerge = false;

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps;
  const site = pageProps.site;

  return (
    <>
      <Bootstrap {...pageProps} />
      {/*
        // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
        // Note Next.js does not (currently) provide anything for translation, only i18n routing.
        // If your app is not multilingual, next-localization and references to it can be removed.
      */}
      <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
        <ThemeContext.Provider value={getTheme(site)}>
          <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <Component {...rest} />
          </CookiesProvider>
        </ThemeContext.Provider>
      </I18nProvider>
    </>
  );
}

export default App;
